<template>
  <div class="content">
    <div class="main">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isAbout2In && 'delay500',
        ]"
        id="about1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/mobile/about/title_bg.png')"
        />
        <div class="title">{{ title }}</div>
      </div>
      <div class="content-card-list" id="about2">
        <div
          :class="[
            'content-card-item',
            'fadein scrollin',
            animationValueMap.isAbout2In && `delay${2 * index + 7}00`,
          ]"
          v-for="(item, index) in cardList"
          :key="index"
        >
          <div class="card-item-box">
            <div
              class="card-item-box-img"
              v-if="item?.image?.type === 'img' && item?.image?.path"
            >
              <img
                :src="
                  require('@/assets/images/version2/about/' + item?.image?.path)
                "
              />
            </div>
            <div
              class="card-item-box-video"
              v-if="item?.image?.type === 'video' && item?.image?.path"
              @click="handleShowVideo"
            >
              <img
                :src="
                  require('@/assets/images/version2/about/' + item?.image?.path)
                "
              />
            </div>
          </div>
          <div class="card-item-name">{{ item?.name || "" }}</div>
          <div class="card-item-decription">{{ item?.decripition || "" }}</div>

          <div class="card-item-control" v-if="item?.control">
            <div
              class="card-item-btn"
              @click="handleToLink(item?.control?.path)"
            >
              <div class="card-item-btn-text">
                {{ item?.control?.name || "" }}
              </div>

              <div class="popup" v-if="item?.control?.isHover">
                <div
                  class="popup-item"
                  v-for="(item, index) in item?.control?.hoverList"
                  :key="index"
                >
                  <div
                    class="popup-item-text"
                    @click="handleToLink(item?.path)"
                  >
                    {{ item?.name || "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="[
          'content-swiper-tips',
          'fadein scrollin',
          animationValueMap.isAbout2In && 'delay700',
        ]"
      >
        {{ `&lt; swipe left and right to see &gt;` }}
      </div>

      <div
        :class="[
          'content-text',
          'fadein scrollin',
          animationValueMap.isAbout3In && 'delay700',
        ]"
        v-html="content"
        id="about3"
      ></div>
    </div>

    <div class="video-popup" v-if="isShowVideo">
      <div class="mask"></div>
      <div
        class="page-video-box"
        @mouseover="handleSeover"
        @mouseout="handleSeout"
      >
        <video-player
          class="video-player vjs-theme-forest"
          playsinline
          src="https://www.yuliverse.com/video/yuliverse0805.mp4"
          crossorigin="anonymous"
          autoplay="false"
          controls
          @play="handleEvent"
          ref="myVideo"
          preload="none"
          :volume="0.6"
          aspectRatio="16:9"
          :techOrder="['html5', 'flvjs']"
          :poster="require(`@/assets/images/feature/backImg.png`)"
          :children="[
            'mediaLoader',
            'posterImage',
            'controlBar',
            'textTrackDisplay',
          ]"
        />
        <div class="page-video-box-play" v-if="isClickSrcPlay">
          <img
            class="page-video-box-play-img"
            :src="isSrcPlay ? srcPlay : srcPlay2"
          />
        </div>
        <div class="page-video-close" @click="handleCloseVideo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { VideoJsPlayer } from "video.js";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
export default {
  components: {
    VideoPlayer,
  },
  name: "YuliAbout",
  props: {},
  data() {
    return {
      title: "About Yuliverse",
      content:
        "A Web 3 metaverse that redefines the boundaries between the digital and real worlds. <br/>Inspired by the Pokémon Go,  interactive experience that blends engaging storylines, social interactions, and a player-driven economy.",
      cardList: [
        {
          name: "Start to Play",
          decripition: "Game on IOS or Google Play Store",
          image: {
            type: "img",
            path: "about_image_1@2x.png",
          },
          control: {
            name: "Download >",
            isHover: true,
            hoverList: [
              {
                name: "Android",
                path: "https://play.google.com/store/apps/details?id=com.blockoor.yuliforoverseas",
              },
              {
                name: "IOS",
                path: "https://apps.apple.com/hk/app/yuliverse/id6469319748",
              },
            ],
          },
        },
        {
          name: "Buy a NFT",
          decripition: "Get yourself a 1.0 or 2.0 NFT to start earning today",
          image: {
            type: "img",
            path: "about_image_2@2x.png",
          },
          control: {
            name: "Buy Now",
            path: "https://marketplace.yuliverse.com/",
          },
        },
        {
          name: "Check our FAQs",
          decripition:
            "Deep dive into the game and learn more by exploring the Universe",
          image: {
            type: "video",
            path: "about_image_3@2x.png",
          },
          control: {
            name: "Whitepaper",
            path: "https://metalab-1.gitbook.io/the-white-paper-of-yuliverse",
          },
        },
      ],
      srcPlay: require(`@/assets/images/feature/play1.png`),
      srcPlay2: require(`@/assets/images/feature/play2.png`),
      isClickSrcPlay: true,
      isSrcPlay: true,
      isShowVideo: false,
      commonWidth: 1920,
      videoWidth: 0,
      clientWidth: 0,
      animationList: [
        { id: "about1", key: "isAbout1In" },
        { id: "about2", key: "isAbout2In" },
        { id: "about3", key: "isAbout3In" },
      ],
      animationValueMap: {
        isAbout1In: false,
        isAbout2In: false,
        isAbout3In: false,
      },
    };
  },
  computed: {
    handleGetHeight() {
      return (picHeight) => {
        return (this.clientWidth * picHeight) / this.commonWidth;
      };
    },
  },
  mounted() {
    this.handleInitAnim();
  },
  methods: {
    handleToLink(path) {
      if (!path) return;
      window.open(path);
    },
    handleEvent() {
      this.isClickSrcPlay = false;
    },
    handleSeover() {
      this.isSrcPlay = false;
    },
    handleSeout() {
      this.isSrcPlay = true;
    },
    handleShowVideo() {
      this.isShowVideo = true;
    },
    handleCloseVideo() {
      this.isShowVideo = false;
    },
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: (100dvw / 375px) * 760px;
  min-height: 760px;
  .webpbg("~@/assets/images/version2/mobile/bg/bg_about_h5@2x.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;

  &-header {
    margin-top: 48px;
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    position: relative;

    .title-decorate {
      width: 279px;
      height: 31px;
      position: absolute;
      left: 0;
      top: 28px;
    }

    .title {
      color: #ffffff;
      font-family: "Tomorrow";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38.49px;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;
      margin-left: 8.72px;
      position: relative;
      text-align: left;
    }
  }

  &-card-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 61px;
    padding-left: 48px;
    padding-right: 48px;
    box-sizing: border-box;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  &-card-item {
    width: 280px;
    height: 393px;
    margin-right: 18px;
    background-image: url("~@/assets/images/version2/mobile/about/card_picture_text@2x.png");
    background-position: center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &:last-child {
      margin-right: 0px;
    }

    .card-item-box {
      margin-top: 12.5px;
      width: 254px;
      height: 215px;
      border-radius: 4px;
      background: #123c4a;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-img,
      &-video {
        width: 254px;
        height: 215px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-video {
        cursor: pointer;
      }
    }

    .card-item-name {
      color: #000000d9;
      text-align: center;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.67px;
      margin-top: 20px;
    }

    .card-item-decription {
      width: 228px;
      color: #000000d9;
      text-align: center;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.67px;
      margin-top: 8px;
    }

    .card-item-control {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 29px;

      .card-item-btn {
        width: 134px;
        height: 34px;
        background-image: url("~@/assets/images/version2/mobile/about/btn_blue_p_160@2x.png");
        background-position: center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &-text {
          color: #000000d9;
          text-align: center;
          font-family: "Tomorrow";
          font-size: 15px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: capitalize;
          margin-bottom: 4px;
        }

        &:hover,
        &:active,
        &:focus {
          background-image: url("~@/assets/images/version2/about/btn_blue_p_160_active@2x.png");

          .popup {
            display: flex;
          }
        }
      }

      .popup {
        width: 166px;
        height: 88px;
        background-image: url("~@/assets/images/version2/about/icon_popup.png");
        background-position: center;
        background-size: 100% 100%;
        position: absolute;
        top: -88px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        transform: rotate(180deg);
        display: none;

        &-item {
          width: 160px;
          height: 34px;
          margin-bottom: 4px;
          color: #0f232e;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transform: rotate(180deg);

          &-text {
            color: #ffffff;
            font-family: "Tomorrow";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          &:hover,
          &:active {
            background-color: #d6f3ff;
            border-radius: 4px;

            .popup-item-text {
              color: #000000;
            }
          }
        }
      }
    }
  }

  &-swiper-tips {
    align-self: center;
    text-align: center;
    color: #ffffffd9;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.67px;
    margin-top: 8px;
  }

  &-text {
    width: 343px;
    color: #ffffffd9;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 32px;
    text-align: left;
    align-self: center;
  }
}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #050a23cc;
    backdrop-filter: blur(7.5px);
    top: 0;
    left: 0;
    animation: maskFade 0.1s ease-in-out;

    @keyframes maskFade {
      0% {
        background: #050a23;
      }
      100% {
        background: #050a23cc;
      }
    }
  }

  .page-video {
    position: relative;
    width: 100%;

    &-box {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      &-play {
        pointer-events: none;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 90px;
        height: 90px;

        &-img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-close {
      width: 26px;
      height: 26px;
      background-image: url("~@/assets/images/version2/about/icon_delete_nor@2x.png");
      background-position: center;
      background-size: 100% 100%;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 12px;
      z-index: 999;
    }
  }

  .video-player {
    background-color: black;
    width: 100%;
  }
}
</style>
