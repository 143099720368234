<template>
  <div class="floating">
    <div
      :style="{ display: `${clientHeight > 100 ? 'block' : 'none'}` }"
      class="backTop"
      @click="handleBackTop"
    >
      <img :src="require('@/assets/images/mobile/icon_top.png')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileTop",
  data() {
    return {
      clientHeight: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    handleBackTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScrollx() {
      this.clientHeight = window.pageYOffset;
    },
  },
};
</script>

<style lang="less" scoped>
.floating {
  position: fixed;
  z-index: 999;
  .backTop {
    position: fixed;
    bottom: 10%;
    right: 15px;
    width: 55px;
    height: 52px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
