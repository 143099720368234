<template>
  <div class="content">
    <div class="main" :style="{ scale: scale }">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isPartners2In && 'delay500',
        ]"
        id="partners1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/about/title_bg.png')"
        />
        <div class="title">{{ title }}</div>
      </div>

      <div
        :class="[
          'backers-list',
          'fadein scrollin',
          animationValueMap.isPartners2In && 'delay700',
        ]"
        id="partners2"
      >
        <div
          class="backers-item"
          v-for="(item, index) in gameUserData"
          :key="index"
        >
          <img
            class="partners"
            :src="require(`@/assets/images/partners/v3/${item.img_src}@2x.png`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnersJson from "@/components/Pc/components/YuliPartners/partners.json";
export default {
  components: {},
  name: "YuliPartner",
  props: {},
  data() {
    return {
      title: "Partners & Backer",
      gameUserData: partnersJson,
      animationList: [
        { id: "partners1", key: "isPartners1In" },
        { id: "partners2", key: "isPartners2In" },
      ],
      animationValueMap: {
        isPartners1In: false,
        isPartners2In: false,
      },
      scale: 1,
    };
  },
  computed: {},
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
    handleResize() {
      const width = document.body.clientWidth;

      let size = width / 1920;
      size = size < 1 ? 1 : size;
      this.scale = size;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  margin-top: ((100dvw / 1920px) * -110px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: (100dvw / 1920px) * 712px;
  min-height: 712px;
  .webpbg("~@/assets/images/version2/bg/bg_partners_v2.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;

  &-header {
    margin-top: ((100dvw / 1920px) * 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    position: relative;

    .title-decorate {
      width: 433px;
      height: 48px;
      position: absolute;
      left: 0;
      top: 43px;
    }

    .title {
      color: #ffffff;
      font-family: "Tomorrow";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 60.14px;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;
      margin-left: 12px;
      position: relative;
      text-align: left;
    }
  }
}

@media screen and (max-width: 1920px) {
  .content {
    margin-top: -110px;

    &-header {
      margin-top: 110px;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin-right: 230px;
}

.backers-list {
  width: 880px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 95px;

  .backers-item {
    width: 214px;
    height: 71px;
    margin-right: 72px;
    margin-bottom: 40px;

    &:nth-child(3n) {
      margin-right: 0px;
    }

    .partners {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
