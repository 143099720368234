<template>
  <div class="content-wrap">
    <div class="content">
      <div class="main" :style="{ scale: scale }">
        <div class="main-box">
          <div class="text-wrap">
            <div class="main-box-text-shadow">Diverse Game Modes</div>
            <div class="main-box-text">Diverse Game Modes</div>
          </div>

          <div class="main-box-btn-list">
            <img
              class="main-btn"
              :src="require('@/assets/images/version2/game/btn_1.png')"
            />
            <img
              class="main-btn"
              :src="require('@/assets/images/version2/game/btn_2.png')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "YuliGame",
  props: {},
  data() {
    return {
      scale: 1,
    };
  },
  computed: {},
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {},
  methods: {
    handleResize() {
      const width = document.body.clientWidth;

      let size = width / 1920;
      size = size < 1 ? 1 : size;
      this.scale = size;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");

.content {
  .webpbg("~@/assets/images/version2/bg/bg_game_v2.png");
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 1920px;
  height: (100dvw / 1920px) * 920px;
  min-height: 920px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom center;

  &-wrap {
    position: sticky !important;
    top: 0px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: #000;
    // padding-top: 80px;
    min-height: 100dvh;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 1920px;
  height: (100dvw / 1920px) * 920px;
  min-height: 920px;
  // height: 100dvh;
  position: relative;
  overflow: hidden;

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: (100dvw / 1920px) * 920px;
    min-width: 1920px;
    min-height: 920px;
    top: -109px;
    // top: -139px;
    bottom: 0;
    left: -120px;
    // left: -250px;
    right: 0px;

    .text-wrap {
      position: relative;
    }

    &-text {
      width: 219px;
      color: #fff3e0;
      text-align: center;
      text-shadow: 0 4px 15px #ffc997;
      font-family: "Tomorrow";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      text-shadow: -2px -2px 0 #be6410, 2px -2px 0 #be6410, -2px 2px 0 #be6410,
        2px 2px 0 #be6410;
    }

    &-text-shadow {
      width: 219px;
      color: #fff3e0;
      text-align: center;
      font-family: "Tomorrow";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      text-shadow: 0 4px 15px #ffc997;
      position: absolute;
      top: 0;
    }

    &-btn-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      .main-btn {
        width: 218px;
        height: 70px;
        margin-bottom: -11px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
