<template>
  <div class="content">
    <div class="main">
      <div class="main-box" id="game1">
        <div
          :class="[
            'text-wrap',
            'fadein scrollin',
            animationValueMap.isGame1In && 'delay500',
          ]"
        >
          <div class="main-box-text-shadow">Diverse Game Modes</div>
          <div class="main-box-text">Diverse Game Modes</div>
        </div>

        <div
          :class="[
            'main-box-btn-list',
            'fadein scrollin',
            animationValueMap.isGame1In && 'delay700',
          ]"
        >
          <img
            class="main-btn"
            :src="require('@/assets/images/version2/game/btn_1.png')"
          />
          <img
            class="main-btn"
            :src="require('@/assets/images/version2/game/btn_2.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "YuliGame",
  props: {},
  data() {
    return {
      animationList: [
        { id: "game1", key: "isGame1In" },
        { id: "game2", key: "isGame2In" },
      ],
      animationValueMap: {
        isGame1In: false,
        isGame2In: false,
      },
    };
  },
  computed: {},
  mounted() {
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  .webpbg("~@/assets/images/version2/mobile/bg/bg_gamemodes_h5@2x.png");
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: (100dvw / 375px) * 760px;
  min-height: 760px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: (100dvw / 375px) * 760px;
  position: relative;

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0px;
    right: 0px;

    .text-wrap {
      position: absolute;
      top: 124px;
      right: 13%;
    }

    &-text {
      width: 219px;
      color: #fff3e0;
      text-align: center;
      text-shadow: 0 4px 15px #ffc997;
      font-family: "Tomorrow";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      text-shadow: -2px -2px 0 #be6410, 2px -2px 0 #be6410, -2px 2px 0 #be6410,
        2px 2px 0 #be6410;
    }

    &-text-shadow {
      width: 219px;
      color: #fff3e0;
      text-align: center;
      font-family: "Tomorrow";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      text-shadow: 0 4px 15px #ffc997;
      position: absolute;
      top: 0;
    }

    &-btn-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 30px;

      .main-btn {
        width: 218px;
        height: 70px;
      }
    }
  }
}
</style>
