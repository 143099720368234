<template>
  <div class="mobile">
    <YuliHeader :is-scroll="isScroll" @showRoute="handleShowRoute"></YuliHeader>
    <MobileTop></MobileTop>
    <RoutePage
      v-show="routeShow"
      :menu-list="menuList"
      :is-scroll="isScroll"
      :active-name="activeName"
      @close="handleCloseRoute"
      @MenuLink="handleMenuLink"
    ></RoutePage>
    <div class="container">
      <MainContent id="mainContent"></MainContent>
      <YuliAbout />
      <YuliGame />
      <YuliNFT />
      <YuliToken />
      <YuliPartners />
      <YuliConnectMobile></YuliConnectMobile>
    </div>
  </div>
</template>

<script>
import YuliHeader from "@/components/Mobile/components/YuliHeader";
import MobileTop from "@/components/Mobile/MobileTop";
import MainContent from "@/components/Mobile/components/MainContent";
import YuliConnectMobile from "@/components/Mobile/components/YuliConnectMobile";
import RoutePage from "@/components/Mobile/components/RoutePage";
import YuliAbout from "@/components/Mobile/components/version2/YuliAbout";
import YuliGame from "@/components/Mobile/components/version2/YuliGame";
import YuliNFT from "@/components/Mobile/components/version2/YuliNFT";
import YuliToken from "@/components/Mobile/components/version2/YuliToken";
import YuliPartners from "@/components/Mobile/components/version2/YuliPartners";

export default {
  components: {
    YuliHeader,
    MobileTop,
    YuliConnectMobile,
    MainContent,
    RoutePage,
    YuliAbout,
    YuliGame,
    YuliNFT,
    YuliToken,
    YuliPartners,
  },
  data() {
    return {
      routeShow: false,
      isScroll: false,
      activeName: "",
      menuList: [
        // { name: "Game", link: "roleplay" },
        // { name: "Feature", link: "feature" },
        // { name: "Character", link: "nft" },
        // { name: "Roadmap", link: "roadMap" },
        // { name: "Token", link: "token" },
        // { name: "Team", link: "team" },
        // { name: "Backers", link: "backers" },
      ],
    };
  },
  mounted() {
    const list = this.menuList;
    list.forEach((item) => {
      item.Top = document.getElementById(item.link)?.offsetTop;
    });
    this.scrollList = list;
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleShowRoute() {
      this.routeShow = true;
    },
    handleCloseRoute() {
      this.routeShow = false;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > window.screen.height - 200) {
        !this.isScroll && (this.isScroll = true);
      } else {
        this.isScroll && (this.isScroll = false);
      }
      if (this.mainOpacity <= 0.9) {
        this.mainOpacity = (scrollTop * 1) / window.screen.height;
      } else {
        this.mainOpacity = 0.9;
      }
      if (scrollTop < window.screen.height - 200) {
        this.activeName = "";
        return;
      }
      this.scrollList.forEach((item) => {
        if (scrollTop - item.Top > -200) {
          this.activeName = item.link;
        }
      });
    },
    handleMenuLink(link) {
      this.routeShow = false;
      let anchorH = document.getElementById(link).offsetTop;
      if (link === "feature") {
        anchorH += 60;
      }

      window.scrollTo({ top: anchorH - 50, behavior: "smooth" });
    },
  },
};
</script>

<style lang="less">
@import url("~@/common/less/webpbg.less");
.mobile {
  // position: relative;
  width: 100%;
}
.container {
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
#nft {
  // .webpbg("~@/assets/images/bg/mobile/bg_7.png");
}
#token {
  z-index: 2;
}
#team {
  // z-index: 2;
  .webpbg("~@/assets/images/bg/mobile/bg_9.png");
}

#roleplay {
  .webpbg("~@/assets/images/bg/mobile/bg_2_3.png");
}

#backers {
  margin-top: -1px;
}
</style>
