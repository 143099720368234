<template>
  <header :class="['header', isScroll && 'menuActive']">
    <!-- <img
      class="logo"
      src="@/assets/images/main/nav_logo.png"
      alt=""
      @click="handleToIndex"
    /> -->
    <img
      class="menu_btn"
      src="@/assets/images/main/mobile/icon_more.svg"
      alt=""
      @click="handleShowRoute"
    />
    <!-- <div class="website">
      <div class="market" @click="handlToMarket">Marketplace</div>
      <div class="special" @click="handlToSpecial">
        Special Event
        <div class="market-tips">Staking Live Now</div>
      </div>
    </div> -->
  </header>
</template>

<script>
export default {
  props: {
    activeBtn: {
      type: String,
      default: "",
    },
    isScroll: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleToIndex() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    handleShowRoute() {
      this.$emit("showRoute");
    },
    handlToSpecial() {
      window.open("https://special-event.yuliverse.com/");
    },
    handlToMarket() {
      window.open("https://marketplace.yuliverse.com/");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 375px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  background: linear-gradient(
    180deg,
    rgba(38, 34, 79, 0.5) 0%,
    rgba(25, 19, 112, 0) 100%
  );
  .logo {
    width: 115px;
    height: 25px;
    margin-left: 25px;
  }
  .menu_btn {
    margin-left: 14px;
    width: 21px;
    height: 20px;
    margin-right: 10px;
  }
  .menu {
    display: flex;
    color: #fff;

    text-align: center;
    &-item {
      cursor: pointer;
      color: #fff;
      text-decoration: none;
      width: 110px;
      height: 88px;
      line-height: 88px;
      font-size: 16px;
      font-weight: 400;
      &:hover {
        opacity: 0.5;
        background-image: url("~@/assets/images/main/active_menu.png");
        background-size: 100% 100%;
        font-weight: 900;
      }
    }
    .active {
      opacity: 1;
      background-image: url("~@/assets/images/main/active_menu.png");
      background-size: 100% 100%;
      font-weight: 900;
    }
    .menuScroll {
      opacity: 0.7;
    }
  }
  .website {
    display: flex;
    justify-content: center;
    align-items: center;
    .special,
    .market {
      position: relative;
      width: 96px;
      height: 27px;
      line-height: 27px;
      color: #fff;
      font-family: "Josefin Sans";
      border-radius: 42px;
      cursor: pointer;
      font-size: 12.8px;
      font-style: normal;
      font-weight: 700;
      border-radius: 4px;
      &-tips {
        background-image: url("~@/assets/images/main/img_tips.png");
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 29px;
        width: 173px;
        height: 50px;
        color: #0f193b;
        line-height: 4;
      }
    }
    .market {
      background: #003959;
      color: #3dc8ff;
      border: 1px solid #3dc8ff;
    }
    .special {
      margin: 0 8px;
      color: #15e2e5;
      border: 1px solid #15e2e5;
      background-color: #003c47;
    }
  }
}
.menuActive {
  background-color: #000;
}
</style>
