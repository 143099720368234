<template>
  <div class="content">
    <div class="main">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isToken2In && 'delay500',
        ]"
        id="token1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/mobile/about/title_bg_3.png')"
        />
        <div class="title">{{ title }}</div>
      </div>

      <div class="content-block" id="token2">
        <div :class="['item-block', 'item-block-arg']">
          <img
            :class="[
              'item-block-img',
              'fadein scrollin',
              animationValueMap.isToken2In && 'delay900',
            ]"
            :src="require('@/assets/images/version2/mobile/token/arg_bg.png')"
          />
          <div class="item-block-content">
            <div
              :class="[
                'fadein scrollin',
                animationValueMap.isToken2In && 'delay900',
              ]"
            >
              $ARG earned through quests and exploration, used for character
              upgrades and etc
            </div>
          </div>
        </div>
        <img
          :class="[
            'content-img',
            'fadein scrollin',
            animationValueMap.isToken2In && 'delay700',
          ]"
          :src="require('@/assets/images/version2/mobile/token/content_bg.png')"
        />
        <div :class="['item-block', 'item-block-art']">
          <img
            :class="[
              'item-block-img',
              'fadein scrollin',
              animationValueMap.isToken2In && 'delay900',
            ]"
            :src="require('@/assets/images/version2/mobile/token/art_bg.png')"
          />
          <div class="item-block-content">
            <div
              :class="[
                'fadein scrollin',
                animationValueMap.isToken2In && 'delay900',
              ]"
            >
              $ART is a valuable currency obtained from high-level activities,
              used for advanced upgrades, special items, and land purchases.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "YuliToken",
  props: {},
  data() {
    return {
      title: "Yuliverse Token",
      animationList: [
        { id: "token1", key: "isToken1In" },
        { id: "token2", key: "isToken2In" },
      ],
      animationValueMap: {
        isToken1In: false,
        isToken2In: false,
      },
    };
  },
  computed: {},
  mounted() {
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: (100dvw / 375px) * 680px;
  min-height: 680px;
  .webpbg("~@/assets/images/version2/mobile/bg/h5_bg_shraptoken@2x.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.content-header {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-left: 16px;

  .title-decorate {
    width: 273px;
    height: 31px;
    position: absolute;
    left: 0;
    top: 30px;
  }

  .title {
    color: #000000;
    font-family: "Tomorrow";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.49px;
    margin-left: 3px;
  }
}

.content-block {
  width: 100%;
  margin-top: 63px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;

  .item-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item-block-img {
      width: 120px;
      height: 32px;
    }

    .item-block-content {
      padding: 8px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #ffffffcc;
      color: #000000d9;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.8;
      text-align: left;
      margin-top: 6px;
    }

    &-arg {
      margin-left: 22px;
      margin-top: 7px;
      .item-block-content {
        width: 209px;
        height: 67px;
      }
    }

    &-art {
      align-self: flex-end;
      margin-right: 16px;
      .item-block-content {
        width: 209px;
        height: 101px;
      }
    }
  }

  .content-img {
    width: 222px;
    height: 204px;
    margin-left: 66px;
  }
}
</style>
