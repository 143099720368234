import { createRouter, createWebHistory } from "vue-router";

import index from "@/views/Pc/index";

/**
 * 创建路由对象
 */
const routes_PC = [
  {
    path: "/",
    meta: {
      title: "Yuliverse",
    },
    component: index,
  },
  // {
  //   path: "/PrivacyPolicy",
  //   meta: {
  //     title: "Yuliverse",
  //   },
  //   component: () => import("@/views/Pc/documents/PrivacyPolicy.vue"),
  // },
  // {
  //   path: "/Agreement",
  //   meta: {
  //     title: "Yuliverse",
  //   },
  //   component: () => import("@/views/Pc/documents/Agreement.vue"),
  // }
];

const routes =[
    ...routes_PC,
]
//创建路由
const router = createRouter({
  history: createWebHistory(),
  routes, //定义的路由
});
// 路由拦截操作
router.beforeEach((to, from, next) => {
  // 拦截标题配置
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
