<template>
  <div class="content">
    <div class="main">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isNft2In && 'delay500',
        ]"
        id="nft1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/mobile/nft/title_bg_2.png')"
        />
        <div class="title">{{ title }}</div>
      </div>

      <div class="content-main" id="nft2">
        <div class="yuli-list">
          <div
            :class="[
              'yuli-item',
              `yuli-item-${item?.name}`,
              'fadein scrollin',
              animationValueMap.isNft2In && `delay${2 * index + 5}00`,
            ]"
            v-for="(item, index) in list1"
            :key="index"
          >
            <img
              class="yuli-item-bg"
              :src="
                require('@/assets/images/version2/mobile/nft/' + item?.path)
              "
              v-if="item?.path"
            />
            <div
              :class="['yuli-type-header', `yuli-type-header-${item?.name}`]"
            >
              <img
                class="yuli-type-icon"
                :src="
                  require('@/assets/images/version2/nft/type/' + item?.icon)
                "
                v-if="item?.icon"
              />
              <div class="yuli-type-text">{{ item?.name || "" }}</div>
            </div>

            <div
              :class="['yuli-descript', `yuli-descript-${item?.name}`]"
              v-html="item?.content || ''"
            ></div>
          </div>
        </div>
        <div class="row2">
          <div class="yuli-list">
            <div
              :class="[
                'yuli-item',
                `yuli-item-${item?.name}`,
                'fadein scrollin',
                animationValueMap.isNft2In && `delay${2 * (index + 3) + 5}00`,
              ]"
              v-for="(item, index) in list2"
              :key="index"
            >
              <img
                class="yuli-item-bg"
                :src="
                  require('@/assets/images/version2/mobile/nft/' + item?.path)
                "
                v-if="item?.path"
              />
              <div
                :class="['yuli-type-header', `yuli-type-header-${item?.name}`]"
              >
                <img
                  class="yuli-type-icon"
                  :src="
                    require('@/assets/images/version2/nft/type/' + item?.icon)
                  "
                  v-if="item?.icon"
                />
                <div class="yuli-type-text">{{ item?.name || "" }}</div>
              </div>

              <div
                :class="['yuli-descript', `yuli-descript-${item?.name}`]"
                v-html="item?.content || ''"
              ></div>
            </div>
          </div>
          <div
            :class="[
              'yuli-block',
              'fadein scrollin',
              animationValueMap.isNft2In && 'delay1300',
            ]"
          >
            <img
              class="yuli-block-bg"
              :src="
                require('@/assets/images/version2/mobile/nft/yuli-block.png')
              "
            />
            <div class="yuli-block-content">
              <div class="title">Yuli NFT</div>
              <div class="text">Consist of 3 different collections of NFT.</div>
              <div :class="['text', 'text-2']">YuliGen1 Gen2 and OG404</div>
              <div class="btn" @click="handleToMore">
                <div class="btn-text">Learn More</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "YuliNFT",
  props: {},
  data() {
    return {
      title: "Yuli NFT",
      list1: [
        {
          width: 138,
          name: "thunder",
          icon: "icon_line_dark_thunder@2x.png",
          content: "Increase Critical<br/>Hit Damage",
          path: "yuli-thunder@2x.png",
          right: 25,
        },
        {
          width: 134,
          name: "fire",
          icon: "icon_line_dark_fire@2x.png",
          content: "Increase Attack Damage",
          path: "yuli-fire@2x.png",
          right: 23,
        },
        {
          width: 134,
          name: "ice",
          icon: "icon_line_dark_ice@2x.png",
          content: "Increase Critical<br/>Hit Rate",
          path: "yuli-ice@2x.png",
          right: 0,
        },
      ],
      list2: [
        {
          width: 134,
          name: "wind",
          icon: "icon_line_dark_wind@2x.png",
          content: "Increase Attack<br/>Damage",
          path: "yuli-wind@2x.png",
          right: 22,
        },
        {
          width: 134,
          name: "rock",
          icon: "icon_line_dark_rock@2x.png",
          content: "Enhance Team<br/>Defense",
          path: "yuli-rock@2x.png",
          right: 27,
        },
      ],
      animationList: [
        { id: "nft1", key: "isNft1In" },
        { id: "nft2", key: "isNft2In" },
      ],
      animationValueMap: {
        isNft1In: false,
        isNft2In: false,
      },
    };
  },
  computed: {},
  mounted() {
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleToMore() {
      const path = "https://bit.ly/49LLtyK";
      window.open(path);
    },
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: (100dvw / 375px) * 680px;
  min-height: 680px;
  .webpbg("~@/assets/images/version2/mobile/bg/bg_yulinft_h5@2x.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.content-header {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-left: 16px;

  .title-decorate {
    width: 215px;
    height: 31px;
    position: absolute;
    left: 0;
    top: 28px;
  }

  .title {
    color: #000000;
    font-family: "Tomorrow";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.49px;
    margin-left: 8px;
    position: relative;
    text-align: left;
  }
}

.content-main {
  margin-top: 53px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // flex-wrap: wrap;

  .yuli-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .yuli-item {
      width: 138px;
      height: 236px;
      margin-right: 0px;
      position: relative;

      &-bg {
        width: 100%;
        height: 100%;
      }

      &-thunder {
        width: 138px;
        margin-right: -25px;
      }

      &-fire {
        width: 134px;
        margin-right: -23px;
      }

      &-ice {
        width: 134px;
        margin-right: 0px;
      }

      &-wind {
        width: 134px;
        margin-right: -22px;
      }

      &-rock {
        width: 134px;
        margin-right: -27px;
      }

      .yuli-type-header {
        width: 78px;
        height: 22px;
        position: absolute;
        background-position: center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &-thunder {
          width: 78px;
          background-image: url("~@/assets/images/version2/mobile/nft/bg/thunder.png");
          bottom: 51px;
          left: 12px;
        }

        &-fire {
          width: 58px;
          background-image: url("~@/assets/images/version2/mobile/nft/bg/fire.png");
          bottom: 42px;
          left: 7.5px;
        }

        &-ice {
          width: 59px;
          background-image: url("~@/assets/images/version2/mobile/nft/bg/ice.png");
          bottom: 52px;
          left: 8px;
        }

        &-wind {
          width: 63px;
          background-image: url("~@/assets/images/version2/mobile/nft/bg/wind.png");
          bottom: 50px;
          left: 7px;
        }

        &-rock {
          width: 58px;
          background-image: url("~@/assets/images/version2/mobile/nft/bg/rock.png");
          bottom: 71px;
          left: 7px;
        }

        .yuli-type-icon {
          width: 14px;
          height: 14px;
          margin-right: 2px;
          margin-left: 7px;
          margin-bottom: 4px;
        }

        .yuli-type-text {
          color: #ffffff;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          margin-bottom: 4px;
        }
      }

      .yuli-descript {
        width: 101px;
        color: #4c4d05;
        font-family: "Inter";
        font-size: 11px;
        font-style: italic;
        font-weight: 600;
        line-height: 14px;
        font-style: italic;
        position: absolute;
        text-align: left;

        &-thunder {
          color: #4c4d05;
          bottom: 19px;
          left: 14px;
        }

        &-fire {
          color: #7f2927;
          left: 9.5px;
          bottom: 10px;
        }

        &-ice {
          color: #115e89;
          left: 9px;
          bottom: 19.72px;
        }

        &-wind {
          color: #026e68;
          left: 8px;
          bottom: 18px;
        }

        &-rock {
          color: #3f2587;
          left: 9px;
          bottom: 39px;
        }
      }
    }
  }

  .yuli-block {
    width: 153px;
    height: 236px;
    position: relative;

    &-bg {
      width: 153px;
      height: 236px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 40px;
      right: 2px;

      .title {
        color: #ffffff;
        text-align: center;
        -webkit-text-stroke-width: 2;
        -webkit-text-stroke-color: #3b45a4;
        font-family: "Tomorrow";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.46px;
        text-shadow: -2px -2px 0 #1b2c58, 2px -2px 0 #3b45a4, -2px 2px 0 #3b45a4,
          2px 2px 0 #3b45a4;
      }

      .text {
        width: 124px;
        color: #ffffff;
        text-align: center;
        // -webkit-text-stroke-width: 2;
        // -webkit-text-stroke-color: #1b2c58;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-shadow: -2px -2px 0 #1b2c58, 2px -2px 0 #1b2c58, -2px 2px 0 #1b2c58,
          2px 2px 0 #1b2c58;
        margin-top: 12px;
      }

      .text-2 {
        margin-top: 10px;
      }

      .btn {
        width: 120px;
        height: 30px;
        background-image: url("~@/assets/images/version2/about/btn_blue_p_160@2x.png");
        background-position: center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 9px;

        &-text {
          color: #000000d9;
          text-align: center;
          font-family: "Tomorrow";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          margin-bottom: 4px;
        }
      }
    }
  }

  .row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 320px) {
  .content-main {
    .yuli-list {
      .yuli-item {
        .yuli-descript {
          width: 107px;
        }
      }
    }
  }
}
</style>
