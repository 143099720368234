import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import smoothscroll from "smoothscroll-polyfill";
import { webBg, webp } from "@/common/webp.js";
import VueLazyload from 'vue-lazyload'

import axios from "axios";
// IE 平滑滚动兼容
smoothscroll.polyfill();

import "amfe-flexible";
import "normalize.css";
const app = createApp(App)
app.directive('webpb',webBg).directive('webp',webp)
app.use(router).use(VueLazyload).mount("#app");
