<template>
  <div class="content">
    <div class="main">
      <div
        :class="[
          'content-header',
          'fadein scrollin',
          animationValueMap.isPartners2In && 'delay500',
        ]"
        id="partners1"
      >
        <img
          class="title-decorate"
          :src="require('@/assets/images/version2/mobile/about/title_bg.png')"
        />
        <div class="title">{{ title }}</div>
      </div>

      <div
        :class="[
          'backers-list',
          'fadein scrollin',
          animationValueMap.isPartners2In && 'delay700',
        ]"
        id="partners2"
      >
        <div
          class="backers-item"
          v-for="(item, index) in gameUserData"
          :key="index"
        >
          <img
            class="partners"
            :src="require(`@/assets/images/partners/v3/${item.img_src}@2x.png`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnersJson from "@/components/Pc/components/YuliPartners/partners.json";
export default {
  components: {},
  name: "YuliPartner",
  props: {},
  data() {
    return {
      title: "Partners & Backer",
      gameUserData: partnersJson,
      animationList: [
        { id: "partners1", key: "isPartners1In" },
        { id: "partners2", key: "isPartners2In" },
      ],
      animationValueMap: {
        isPartners1In: false,
        isPartners2In: false,
      },
    };
  },
  computed: {},
  mounted() {
    this.handleInitAnim();
  },
  beforeUnmount() {},
  methods: {
    handleInitAnim() {
      const observer = new IntersectionObserver((mutaions) => {
        for (const i in mutaions) {
          const isIn = mutaions[i]?.isIntersecting;
          const target = mutaions[i]?.target;
          const id = target?.id;

          const item = this.animationList.find((item) => {
            return item.id === id;
          });

          const key = item?.key;
          if (!key) continue;
          const value = this.animationValueMap[key];

          if (isIn && !value) {
            this.animationValueMap[key] = true;
          } else if (!isIn && value) {
            this.animationValueMap[key] = false;
          }
        }
      });

      for (const i in this.animationList) {
        const id = this.animationList[i].id;
        const el = document.querySelector(`#${id}`);
        if (el) {
          observer.observe(el);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
@import url("~@/common/animation.less");

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: (100dvw / 375px) * 650px;
  min-height: 650px;
  .webpbg("~@/assets/images/version2/mobile/bg/H5_bg_paetners@2x.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;

  &-header {
    width: 100dvh;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-left: 16px;

    .title-decorate {
      width: 279px;
      height: 31px;
      position: absolute;
      left: 0;
      top: 28px;
    }

    .title {
      color: #ffffff;
      font-family: "Tomorrow";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 36.09px;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;
      margin-left: 3px;
      position: relative;
      text-align: left;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin-right: 230px;
}

.backers-list {
  width: 134px;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 63px;
  margin-left: 24px;

  .backers-item {
    width: 134px;
    height: 45px;
    margin-bottom: 24px;
    margin-right: 50px;

    &:nth-child(6n) {
      margin-bottom: 0px;
    }

    .partners {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
