<template>
  <div class="page">
    <div class="connected-decorate-box">
      <div class="connected-decorate"></div>
    </div>
    <div class="connected">
      <div class="connected-left">
        <div class="connected-left-touch">CONTACT US</div>
        <div class="connected-left-message">
          <!-- <div>Telegram: @yuliverseofficialsupport</div> -->
          <div>
            Email: support@yuliverse.com &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            partnership@yuliverse.com
          </div>
        </div>
      </div>
      <div class="connected-right">
        <div class="connected-right-stay">Stay connected:</div>
        <div class="connected-right-link">
          <a href="http://twitter.com/TheYuliverse" target="_blank"
            ><img
              :src="
                oneImg
                  ? iconData[2].imgUrl
                  : require('@/assets/images/main/icon_twitte.png')
              "
              @mouseenter="handleImg(3)"
              @mouseleave="handleImgOVer(3)"
          /></a>
          <!-- 
          <a href="http://t.me/YuliverseOfficial" target="_blank"
            ><img
              :src="
                twoImg
                  ? iconData[1].imgUrl
                  : require('@/assets/images/gameUser/icon_telegram@2x.png')
              "
              @mouseenter="handleImg(4)"
              @mouseleave="handleImgOVer(4)"
          /></a> -->
          <a href="http://discord.gg/Yuliverseofficial" target="_blank"
            ><img
              :src="
                threeImg
                  ? iconData[0].imgUrl
                  : require('@/assets/images/gameUser/icon_discord@2x.png')
              "
              @mouseenter="handleImg(5)"
              @mouseleave="handleImgOVer(5)"
          /></a>
        </div>
        <div class="connected-right-logo">
          <img
            class="connected-right-logo-img"
            :src="require('@/assets/images/gameUser/logomin.png')"
          />
          <div>@2022.yuliverse</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YuliConnect",
  data() {
    return {
      iconData: [
        {
          imgUrl: require("@/assets/images/main/icon_discord_selet@2x.png"),
        },
        {
          imgUrl: require("@/assets/images/main/icon_telegram_selet@2x.png"),
        },
        {
          imgUrl: require("@/assets/images/main/icon_twitte_selet@2x.png"),
        },
      ],
      oneImg: false,
      twoImg: false,
      threeImg: false,
    };
  },
  methods: {
    handleImg(index) {
      if (index === 3) {
        this.oneImg = true;
      }
      if (index === 4) {
        this.twoImg = true;
      }
      if (index === 5) {
        this.threeImg = true;
      }
    },
    handleImgOVer(index) {
      if (index === 3) {
        this.oneImg = false;
      }
      if (index === 4) {
        this.twoImg = false;
      }
      if (index === 5) {
        this.threeImg = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Josefin Sans";
  background-color: black;
  // background-image: url("~@/assets/images/bg/bg_11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 284px;
  position: relative;
  margin-top: -1px;

  .connected {
    width: 1144.39px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    &-decorate {
      width: 522px;
      height: 284px;
      background-image: url("~@/assets/images/main/footer.png");
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      left: -55px;

      &-box {
        width: 1144.39px;
        position: relative;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      &-contact,
      &-message {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        font-family: "Inter";
        text-align: left;
        line-height: 30px;
        width: 559.1px;
        font-style: normal;
        font-weight: 400;
      }

      &-touch {
        height: 68px;
        line-height: 68px;
        color: #ffffff;
        font-size: 36px;
        font-weight: 700;
        font-family: "Tomorrow";
        text-align: left;
        margin-bottom: 24px;
        font-style: normal;
        line-height: normal;
      }
    }

    &-right {
      &-stay {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        font-family: "Inter";
        text-align: left;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &-link {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          display: inline-block;
          font-size: 0;
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
      }

      &-logo {
        margin-top: 24px;
        display: flex;
        align-items: flex-end;
        color: #999999;
        font-size: 18px;
        font-weight: 400;
        font-family: "Inter";
        font-style: normal;
        line-height: normal;

        &-img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
