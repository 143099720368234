<template>
  <div id="app">
    <YuliHeader
      :is-scroll="isScroll"
      :active-btn="activeName"
      :menu-list="menuList"
      @MenuLink="handleMenuLink"
      @backTop="handleBackTop"
    ></YuliHeader>
    <FloatingLink
      :is-scroll="isScroll"
      :active-btn="activeName"
      :menu-list="menuList"
      @MenuLink="handleMenuLink"
      @backTop="handleBackTop"
    ></FloatingLink>
    <main id="container">
      <MainContent
        id="services"
        v-webpb
        :main-opacity="mainOpacity"
        :zIndex="zIndex"
      ></MainContent>
      <YuliAbout id="v2_about" />
      <YuliGame id="v2_game" />
      <YuliNFT id="v2_nft" />
      <YuliToken id="v2_token" />
      <YuliPartner id="v2_partner" />
      <YuliConnect id="faq"></YuliConnect>
    </main>
  </div>
</template>

<script>
import YuliHeader from "@/components/Pc/components/YuliHeader";
import MainContent from "@/components/Pc/components/Main/MainContent";
import FloatingLink from "@/components/FloatingLink";
import YuliAbout from "@/components/Pc/components/version2/YuliAbout";
import YuliGame from "@/components/Pc/components/version2/YuliGame";
import YuliNFT from "@/components/Pc/components/version2/YuliNFT";
import YuliToken from "@/components/Pc/components/version2/YuliToken";
import YuliConnect from "@/components/Pc/components/YuliConnect";
import YuliPartner from "@/components/Pc/components/version2/YuliPartners";

export default {
  name: "IndexPage",
  components: {
    YuliHeader,
    MainContent,
    FloatingLink,
    YuliAbout,
    YuliGame,
    YuliNFT,
    YuliToken,
    YuliPartner,
    YuliConnect,
  },
  data() {
    return {
      isScroll: false,
      menuList: [
        // { name: "Game", link: "rolePlay" },
        // { name: "Feature", link: "feature" },
        // { name: "Character", link: "nft" },
        // { name: "Token", link: "token" },
        // { name: "Team", link: "team" },
        // { name: "Backers", link: "backers" },
      ],
      scrollList: [],
      mainOpacity: 0,
      zIndex: 2,
      activeName: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      const list = this.menuList;
      list.forEach((item) => {
        item.Top = document.getElementById(item.link).offsetTop;
      });
      this.scrollList = list;
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > window.screen.height - 200) {
        !this.isScroll && (this.isScroll = true);
        this.zIndex = 0;
      } else {
        this.isScroll && (this.isScroll = false);
        this.zIndex = 2;
      }
      if (this.mainOpacity <= 0.9) {
        this.mainOpacity = (scrollTop * 1) / window.screen.height;
      } else {
        this.mainOpacity = 0.9;
      }

      if (scrollTop < window.screen.height - 600) {
        this.activeName = "";
        return;
      }
      this.scrollList.forEach((item) => {
        if (scrollTop - item.Top > -300) {
          this.activeName = item.link;
        }
      });
    },
    handleMenuLink(link) {
      let anchorH = document.getElementById(link).offsetTop;
      if (link === "feature") {
        anchorH += 120;
      }
      if (link === "rolePlay") {
        anchorH += 260;
      }
      if (link === "roadMap") {
        anchorH += 250;
      }
      window.scrollTo({ top: anchorH, behavior: "smooth" });
    },
    handleBackTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.mainOpacity = 0;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
#app {
  width: 100%;
  min-width: 1200px;
}
#container {
  width: 100%;
  position: relative;
  #services {
    position: fixed;
    top: 0;
  }
  #token,
  #argArt,
  #rolePlay,
  #feature,
  #deep,
  #fete,
  #nft,
  #team,
  #backers,
  #faq,
  #power,
  #roadMap {
    position: relative;
    top: 100vh;
    overflow: hidden;
    position: relative;
  }

  #v2_about,
  #v2_game,
  #v2_nft,
  #v2_token,
  #v2_partner {
    position: relative;
    top: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  #v2_game {
    z-index: 1;
  }

  // #feature {
  //   .webpbg("~@/assets/images/bg/bg_feature.png");
  // }
  #deep {
    .webpbg("~@/assets/images/bg/bg_deep.png");
  }
  #fete {
    .webpbg("~@/assets/images/bg/bg_fete.png");
  }
  #power {
    .webpbg("~@/assets/images/bg/bg_04.png");
  }
  #team {
    .webpbg("~@/assets/images/bg/bg_09.png");
  }
}
</style>
