<template>
  <div class="page">
    <div class="connected">
      <div class="connected-left">
        <div class="connected-left-touch">CONTACT US</div>
        <div class="connected-left-message">
          <!-- <div>Telegram: @yuliverseofficialsupport</div> -->
          <div>Email: support@yuliverse.com</div>
          <div>partnership@yuliverse.com</div>
        </div>
      </div>
      <div class="connected-right">
        <div class="connected-right-link">
          <div class="connected-right-stay">Stay connected:</div>
          <div class="connected-right-link-box">
            <a href="http://twitter.com/TheYuliverse" target="_blank"
              ><img :src="require('@/assets/images/main/icon_twitte.png')"
            /></a>

            <!-- <a href="http://t.me/YuliverseOfficial" target="_blank"
              ><img
                :src="require('@/assets/images/mobile/icon_telegram@2x.png')"
            /></a> -->
            <a href="http://discord.gg/Yuliverseofficial" target="_blank"
              ><img
                :src="require('@/assets/images/mobile/icon_discord@2x.png')"
            /></a>
          </div>
        </div>
        <div class="connected-right-logo">
          <img
            class="connected-right-logo-img"
            src="@/assets/images/gameUser/logomin.png"
          />
          <div class="connected-right-logo-years">@2022.yuliverse</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YuliConnectMobile",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page {
  position: relative;
  font-family: "Josefin Sans";
  // background-color: black;
  height: (100dvw / 375px) * 304px;
  min-height: 304px;
  background-image: url("~@/assets/images/bg/mobile/bg_10.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;

  .connected {
    padding-top: 33px;
    margin: 0 auto;
    width: 330px;

    &-left {
      padding-top: 32px;

      &-message {
        font-family: "Inter";
        color: rgba(255, 255, 255, 1);
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        line-height: 22px;
      }

      &-touch {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 16px;
        font-family: "Inter";
      }
    }

    &-right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &-stay {
        color: rgba(255, 255, 255, 1);
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        margin-top: 40px;
        margin-bottom: 4px;
      }

      &-link {
        &-box {
          height: 25px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          a {
            height: 25px;
          }

          img {
            display: inline-block;
            font-size: 0;
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
        }
      }

      &-logo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #999999;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter";

        &-img {
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }

        &-years {
        }
      }
    }
  }
}
</style>
