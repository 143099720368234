<template>
  <header :class="['header', isScroll && 'menuActive']">
    <img
      class="logo"
      src="@/assets/images/main/nav_logo.png"
      alt=""
      @click="handleToIndex"
    />
    <div :class="['menu']">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['menu-item', activeBtn === item.name && 'active']"
        @click="handleMenuLink(item.link)"
        @mouseover="handleMenuHover(item.name)"
        @mouseleave="handleMenuHover('')"
      >
        {{ item.name }}
        <!-- <div v-if="activeBtn === item.name" class="menu-item-bottom">
          <div class="line"></div>
          <img src="@/assets/images/main/nav_pic.png" alt="" />
        </div> -->
        <div v-if="activeBtn === item.name && item.children" class="subList">
          <div
            class="subList-item"
            v-for="(subItem, subItemIndex) in item.children"
            :key="subItemIndex"
            @click="handleMenuLink(subItem.link)"
          >
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="website">
      <div class="market" @click="handlToMarket">Marketplace</div>
      <div class="special" @click="handlToSpecial">
        Special Event
        <div class="market-tips">Staking Live Now</div>
      </div>
    </div> -->
  </header>
</template>

<script>
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeBtn: "",
      tabList: [
        {
          name: "Start Gaming",
          link: "",
          children: [
            {
              name: "How To Play",
              link: `https://special-event.yuliverse.com/activate`,
            },
            {
              name: "Activate Gen1 Yuli",
              link: "https://special-event.yuliverse.com/activate/YuliGen1",
            },
            {
              name: "Activate Gen2 Yuli",
              link: "https://special-event.yuliverse.com/activate/YuliGen2",
            },
            {
              name: "Team Annihilation",
              link: "https://special-event.yuliverse.com/Annihilation",
            },
          ],
        },
        {
          name: "Mint Yuli",
          link: "",
          children: [
            {
              name: "Mint Gen1 Yuli",
              link: "https://special-event.yuliverse.com/sales/YuliGen1",
            },
            {
              name: "Mint Gen2 Yuli",
              link: "https://special-event.yuliverse.com/sales/YuliGen2",
            },
          ],
        },
        // {
        //   name: "Stake",
        //   link: "https://special-event.yuliverse.com/sales/YuliStaking",
        // },
        // {
        //   name: "ART Airdrop",
        //   link: "https://airdrop.yuliverse.com/",
        // },
        {
          name: "CAMPAIGN",
          link: "https://special-event.yuliverse.com/",
        },
        // {
        //   name: "MoonRing",
        //   link: "https://moonring.ai",
        // },
        {
          name: "MARKETPLACE",
          link: "https://marketplace.yuliverse.com/",
        },
      ],
    };
  },
  methods: {
    handleToIndex() {
      this.$emit("backTop");
    },
    handleMenuLink(link) {
      // this.$emit("MenuLink", link);
      if (link) {
        window.open(link);
      }
    },
    handlToSpecial() {
      window.open("https://special-event.yuliverse.com/");
    },
    handlToMarket() {
      window.open("https://marketplace.yuliverse.com/");
    },
    handleMenuHover(link) {
      this.activeBtn = link;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(38, 34, 79, 0.5) 0%,
    rgba(25, 19, 112, 0) 100%
  );

  .logo {
    width: 190px;
    height: 46px;
    margin-left: 3.33%;
    // margin-right: 9%;
    cursor: pointer;
    .img {
      width: 165px;
    }
  }

  @media only screen and (max-width: 980px) {
    .logo {
      width: 115px;
      height: 25px;
    }
  }

  .menu {
    display: flex;
    color: #fff;
    text-align: center;
    margin-right: 3.33%;

    &-item {
      cursor: pointer;
      color: #fff;
      text-decoration: none;
      max-width: 100px;
      height: 80px;
      line-height: 80px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      background-repeat: no-repeat;
      background-position: center;
      font-family: "Tomorrow";
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &-bottom {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        align-items: flex-end;
        img {
          width: 25px;
          height: 13px;
        }
        .line {
          width: 100%;
          height: 2px;
          background: #00b9fc;
        }
      }
    }
    .menu-item + .menu-item {
      margin-left: 64px;
    }
    .active {
      color: #73d7ff;
      // font-weight: 900;
    }
    .menuScroll {
      opacity: 0.7;
    }
    .subList {
      position: absolute;
      top: 80px;
      color: #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      padding: 8px;
      background: #000000b3;
      border-radius: 0 0 8px 8px;
      &-item {
        width: 172px;
        height: 48px;
        line-height: 48px;
        &:hover {
          background-color: #d6f3ff;
          border-radius: 8px;
          color: #000000b3;
        }
      }
      &-item + &-item {
        margin-top: 8px;
      }
    }
  }

  .website {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 72px;
    .special,
    .market {
      position: relative;
      width: 120px;
      height: 34px;
      line-height: 40px;
      color: #fff;
      font-family: "Josefin Sans";
      border-radius: 42px;
      margin-left: 13px;
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      border-radius: 4px;
      &-tips {
        background-image: url("~@/assets/images/main/img_tips.png");
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 29px;
        width: 173px;
        height: 50px;
        color: #0f193b;
        line-height: 4;
      }
    }
    .market {
      background: #003959;
      color: #3dc8ff;
      border: 1px solid #3dc8ff;
    }
    .special {
      color: #15e2e5;
      border: 1px solid #15e2e5;
      background-color: #003c47;
    }
  }
}
.menuActive {
  background-color: #000;
}
</style>
